import React from "react"

export const Live = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 15.617a3.617 3.617 0 100-7.234 3.617 3.617 0 000 7.234z"
      fill="currentColor"
    />
    <path
      d="M12 22C6.495 22 2 17.505 2 12S6.495 2 12 2s10 4.495 10 10-4.468 10-10 10zm0-16.649a6.623 6.623 0 106.622 6.622C18.65 8.33 15.67 5.351 12 5.351z"
      fill="currentColor"
    />
  </svg>
)

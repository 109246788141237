const FACEBOOK = {
  title: "Facebook",
  icon: "facebook",
  url: "https://www.facebook.com/PlayNostalgie",
}

const INSTAGRAM = {
  title: "Instagram",
  icon: "instagram",
  url: "https://www.instagram.com/play.nostalgie/",
}

const TIKTOK = {
  title: "TikTok",
  icon: "tiktok",
  url: "https://www.tiktok.com/@playnostalgie",
}

const YOUTUBE = {
  title: "Youtube",
  icon: "youtube",
  url: "https://www.youtube.com/@playnostalgie./featured",
}

const GENERAL_MAIL = {
  title: "hey@playnostalgie.be",
  icon: "mail",
  url: "mailto:hey@playnostalgie.be"
}

const PERS_MAIL = {
  title: "pers@playnostalgie.be",
  icon: "mail",
  url: "mailto:pers@playnostalgie.be"
}

const BEACH_MAIL = {
  title: "festival@playnostalgie.be",
  icon: "mail",
  url: "mailto:festival@playnostalgie.be"
}

const TWITTER = {
  title: "Youtube",
  icon: "twitter",
  url: "https://twitter.com/NostalgieEU",
}

// const SPOTIFY = {
//   title: "Spotify",
//   icon: "spotify",
//   url: "https://open.spotify.com/user/1175cfnm2j2tc44ujsfmwk1sz?si=H5b320D3SGWEeYer7qF36A",
// }

export const HERO_SOCIALS = [FACEBOOK, INSTAGRAM, YOUTUBE, TIKTOK]

export const FOOTER_SOCIALS = [FACEBOOK, INSTAGRAM, YOUTUBE, TIKTOK]

export const RADIOPLAYER_SOCIALS = [
  FACEBOOK,
  INSTAGRAM,
  TWITTER,
  GENERAL_MAIL,
]

export const CONTACT_SOCIALS = {
  general: GENERAL_MAIL,
  pers: PERS_MAIL,
  beachFestival: BEACH_MAIL,
}
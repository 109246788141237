import React from "react"

export const ChevronLeft = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.487 19.509L7 11.986l7.544-7.504c.62-.62 1.66-.653 2.282-.032.62.621.584 1.648-.041 2.27l-5.315 5.257 5.254 5.295c.621.625.658 1.665.032 2.286-.625.62-1.656.572-2.277-.049"
      fill="currentColor"
    />
  </svg>
)

import { DateTime } from "luxon"
import { isArray } from "lodash"
import { getHeaderSingleImage, getTitle } from "./Publications"
import { formatSingleStation, formatStations } from "./Stations"
import fallbackImg from "../components/Hero/fallback-header.png"

export function formatCarouselData(data) {
  const response = {
    carousel: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  const carouselSection = {
    context: "carousel",
    slides: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug[keyName]
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    carouselSection.slides.push(publication.items[keyName])
  })

  response.carousel = carouselSection

  return response
}

export function formatHomePageGridData(data) {
  const response = {
    grid: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
    status: 200,
  }

  const gridSection = {
    context: "grid",
    items: [],
  }

  let block1 = null
  let block2 = null
  let block3 = null
  let block4 = null

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    if (publication.items[keyName].tag) {
      if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block1")
      ) {
        if (block1 === null) {
          block1 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block2")
      ) {
        if (block2 === null) {
          block2 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block3")
      ) {
        if (block3 === null) {
          block3 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block4")
      ) {
        if (block4 === null) {
          block4 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else {
        gridSection.items.push(publication.items[keyName])
      }
    } else {
      gridSection.items.push(publication.items[keyName])
    }
  })

  if (block1 !== null) {
    gridSection.items.splice(0, 0, block1)
  }

  if (block2 !== null) {
    gridSection.items.splice(1, 0, block2)
  }

  if (block3 !== null) {
    gridSection.items.splice(2, 0, block3)
  }

  if (block4 !== null) {
    gridSection.items.splice(9, 0, block4)
  }

  response.grid = gridSection

  return response
}

export function formatTopListsPageGridData(data) {
  const response = {
    grid: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  const gridSection = {
    context: "grid",
    items: [],
  }

  let block1 = null
  let block2 = null
  let block3 = null
  let block4 = null
  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    if (publication.items[keyName].tag) {
      if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block1")
      ) {
        if (block1 === null) {
          block1 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block2")
      ) {
        if (block2 === null) {
          block2 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block3")
      ) {
        if (block3 === null) {
          block3 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else if (
        publication.items[keyName].tag.items.find(tag => tag.body === "block4")
      ) {
        if (block4 === null) {
          block4 = publication.items[keyName]
        } else {
          gridSection.items.push(publication.items[keyName])
        }
      } else {
        gridSection.items.push(publication.items[keyName])
      }
    } else {
      gridSection.items.push(publication.items[keyName])
    }
  })

  if (block1 !== null) {
    gridSection.items.splice(0, 0, block1)
  }

  if (block2 !== null) {
    gridSection.items.splice(1, 0, block2)
  }

  if (block3 !== null) {
    gridSection.items.splice(2, 0, block3)
  }

  if (block4 !== null) {
    gridSection.items.splice(9, 0, block4)
  }

  response.grid = gridSection

  return response
}

export function formatProgramsData(data) {
  const response = {
    grid: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  const gridSection = {
    context: "programsGrid",
    items: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    gridSection.items.push(publication.items[keyName])
  })

  response.grid = gridSection

  return response
}

export function formatListenPlatforms(data, ids) {
  const platforms = []

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    platforms.push(publication.items[keyName])
  })

  return platforms.sort((a, b) => {
    const indexA = ids.indexOf(a.id);
    const indexB = ids.indexOf(b.id);
    return indexA - indexB;
  })
}

export function formatStationsData(data) {
  const stationsSection = {
    context: "stationsData",
    items: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
      stationsSection.items.push(publication.items[keyName])
  })

  return formatStations(stationsSection.items ?? []);
}

export function formatStationsCarouselData(data) {
  const stationsCarousel = {
    context: "stationsCarouselData",
    title: "Luister ook naar",
    stations: []
  }
  const publication = data?.items?.[0] ?? null;
  if(publication) {
    const keyName = Object.keys(publication.items)[0]
    stationsCarousel.title = publication.items[keyName]?.title?.body ?? 'Luister ook naar'
    stationsCarousel.stations = formatStationsData(publication.items[keyName]?.stations) ?? [];
  }

  return stationsCarousel;
}

export function formatStationData(data) {
  let formattedPublication = null

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""

    if (publication.containers) {
      publication.items[keyName].container_id = publication.containers[0].id
    }

    formattedPublication = publication.items[keyName]
  })
  return formatSingleStation(formattedPublication, 0)
}

export function formatVacanciesData(data) {
  const response = {
    grid: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  const gridSection = {
    context: "programsGrid",
    items: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    gridSection.items.push(publication.items[keyName])
  })

  response.grid = gridSection

  return response
}

export function formatFaqsData(data) {
  const response = {
    grid: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  const gridSection = {
    context: "faqsGrid",
    items: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    gridSection.items.push(publication.items[keyName])
  })

  response.grid = gridSection

  return response
}

export function formatRelatedNewsData(data) {
  const collection = []
  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    collection.push(publication.items[keyName])
  })
  return collection
}

export function formatSinglePublication(data) {
  let formattedPublication = null

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""

    if (publication.containers) {
      publication.items[keyName].container_id = publication.containers[0].id
    }

    formattedPublication = publication.items[keyName]
  })
  return formattedPublication
}

export function formatCurrentProgram(data) {
  let currentProgram = null
  const currentDay = data[DateTime.local().toFormat("yyyy-LL-dd")]
  if (currentDay) {
    currentDay.map(show => {
      let from = DateTime.fromFormat(show.from, "HH:mm:ss")
      let until = DateTime.fromFormat(show.until, "HH:mm:ss")
      if (DateTime.local() > from && DateTime.local() < until) {
        currentProgram = {
          image: show.show.cover
            ? show.show.cover.cdn_files[0].resized.player
            : null,
          showName: show.show.name,
          referenceId: show.show.reference_id,
        }
      }
    })
  }
  return currentProgram
}

export function formatGuide(data) {
  return data[DateTime.local().toFormat("yyyy-LL-dd")] ?? []
}

export function formatRangeGuide(data) {
  const schedule = []
  Object.keys(data).forEach(key => {
    schedule.push({ date: key, slots: data[key] })
  })

  return schedule
}

export function formatTracks(data) {
  const response = {
    items: [],
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    response.items.push(publication.items[keyName])
  })

  return response
}

export async function formatVotes(data, dataVote) {
  const response = {
    items: [],
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    const vote = (publication.items[keyName].vote_id = dataVote.items.find(
      item => item.data.track_id === publication.items[keyName].id
    ))
    publication.items[keyName].vote_id = vote ? vote.id : null
    response.items.push(publication.items[keyName])
  })

  return response
}

export function getImageType(
  type,
  image,
  image_website_carousel,
  image_website_block,
  image_website_detail,
  tile_large,
  tile_medium,
  tile_small,
  hero_detail
) {
  if (type) {
    if (type === "image_website_block") {
      if (image_website_block) {
        if (image_website_block.items[0].cdn_files) {
          return image_website_block
        }
      }
    } else if (type === "image_website_carousel") {
      if (image_website_carousel) {
        if (image_website_carousel.items[0].cdn_files) {
          return image_website_carousel
        }
      }
    } else if (type === "image_website_detail") {
      if (image_website_detail) {
        if (image_website_detail.items[0].cdn_files) {
          return image_website_detail
        }
      }
    } else if (type === "tile_large") {
      if (tile_large) {
        if (tile_large.items[0].cdn_files) {
          return tile_large
        }
      }
    } else if (type === "tile_medium") {
      if (tile_medium) {
        if (tile_medium.items[0].cdn_files) {
          return tile_medium
        }
      }
    } else if (type === "tile_small") {
      if (tile_small) {
        if (tile_small.items[0].cdn_files) {
          return tile_small
        }
      }
    } else if (type === "hero_detail") {
      if (hero_detail) {
        if (hero_detail.items[0].cdn_files) {
          return hero_detail
        }
      }
    }
  }
  return image
}

export function formatPodcastShowsData(data) {
  const response = {
    grid: null,
    total: data.total,
    skip: data.skip,
    limit: data.limit,
  }

  const gridSection = {
    context: "podcastShowsGrid",
    items: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    gridSection.items.push(publication.items[keyName])
  })

  response.grid = gridSection

  return response
}

export function formatPodcastSinglesData(data) {
  const podcasts = {
    items: [],
  }

  data.items.map(publication => {
    const keyName = Object.keys(publication.items)[0]
    publication.items[keyName].id = publication.id
    publication.items[keyName].model_id = publication.model.id
    publication.items[keyName].slug = publication.slug
      ? publication.slug[keyName]
      : ""
    publication.items[keyName].publish_on = publication.publish_on
      ? publication.publish_on[keyName]
      : ""
    podcasts.items.push(publication.items[keyName])
  })

  return podcasts
}

export function formatHeader(data) {
  let header =  {
    title: 'Play Nostalgie',
    subtitle: 'Play Nostalgie',
    primaryImage: fallbackImg,
    titleImage: null,
  }

  if(!data) {
    return header;
  }
  const keyName = Object.keys(data.items);
  if(isArray(keyName)) {
    const item = data.items[keyName[0]] ?? null;
    if(item) {
      header.title = getTitle(item.title);
      header.primaryImage = getHeaderSingleImage(item.primary_image) ?? fallbackImg;
      header.titleImage = getHeaderSingleImage(item.title_image) ?? null;
    }
  }
  return header
}

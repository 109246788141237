import { imageUrl } from "./ImageUrl";
import { getFallbackStream, getMaRadioId, getProvider, getSingleImage, getStationColor, getStationId, getStationPosition, getSubtitle, getTitle, getWEBStream } from "./Publications";

export const formatStations = stations => {
  return stations
    .sort((a, b) =>
        getStationPosition(a.position) > getStationPosition(b.position) ? 1 : -1,
      )
    .map((station, index) => formatSingleStation(station, index))
};

export const formatSingleStation = (station, index) => {
 return {
      id: getStationId(station.station_id),
      url:getWEBStream(station.web_stream),
      title: getTitle(station.title),
      subTitle: getSubtitle(station.subtitle),
      artist: getProvider(station.provider),
      artWork: imageUrl(getSingleImage(station.image_website), 380, 80),
      thumbnail: imageUrl(getSingleImage(station.image_mobile), 360, 360),
      color: getStationColor(station.color),
      websiteUrl: `${process.env.WEBSITE_URL}/luister/${station.slug}`,
      maRadioId: getMaRadioId(station.maradio_id),
      stationUrl: `${process.env.WEBSITE_URL}/luister/${station.slug}`,
      fallback: getFallbackStream(station.fallback_stream),
      slug: station.slug,
      index: index,
 }
};

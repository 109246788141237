import React from "react"

export const Close = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M14.691 12.212l5.225-5.225c.68-.68.728-1.82.049-2.5-.68-.678-1.807-.634-2.49.045l-5.243 5.23-5.248-5.217c-.679-.679-1.815-.714-2.495-.035-.679.68-.639 1.802.045 2.482l5.247 5.185-4.786 4.83c-.679.683-.719 1.82-.035 2.5.684.678 1.811.625 2.49-.054l4.795-4.821 4.821 4.834c.68.684 1.811.724 2.495.045.684-.68.644-1.798-.031-2.482l-4.83-4.817h-.009z"
      fill="currentColor"
    />
  </svg>
)

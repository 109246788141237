import React from "react"

export const Check = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.532 12.678L10.225 19 21.082 7.81c.633-.658.645-1.737-.012-2.369-.658-.632-1.724-.569-2.356.089l-8.577 8.825-4.329-4.05c-.662-.632-1.745-.65-2.373.013-.628.661-.56 1.732.101 2.36"
      fill="currentColor"
    />
  </svg>
)

import React from "react"

export const Youtube = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M21 15.582c0 1.399-1.223 2.622-2.621 2.622H5.62C4.223 18.204 3 16.98 3 15.582V8.418c0-1.398 1.223-2.62 2.621-2.62H18.38c1.398 0 2.621 1.222 2.621 2.62v7.165zm-10.835-1.398l4.893-2.446-4.893-2.447v4.893z"
      fill="currentColor"
    />
  </svg>
)

import React from "react"

export const Spotify = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.915 10.866c-3.223-1.914-8.54-2.09-11.617-1.156a.935.935 0 11-.543-1.79c3.532-1.072 9.404-.865 13.115 1.338a.935.935 0 11-.955 1.608zM17.81 13.7a.78.78 0 01-1.072.257c-2.688-1.652-6.786-2.13-9.965-1.165A.78.78 0 016.32 11.3c3.632-1.102 8.147-.568 11.233 1.329a.78.78 0 01.257 1.071zm-1.224 2.724a.623.623 0 01-.857.208c-2.348-1.435-5.304-1.76-8.785-.964a.623.623 0 11-.277-1.215c3.81-.871 7.077-.496 9.712 1.114.294.18.387.564.207.857zM12 2C6.477 2 2 6.477 2 12s4.477 10 10 10 10-4.477 10-10S17.523 2 12 2z"
      fill="currentColor"
    />
  </svg>
)

import React from "react"

export const User = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.812 20.963a5.657 5.657 0 00-5.657-5.657H5V21h3.064v-2.63h6.091a2.596 2.596 0 012.593 2.593h3.064zM11.989 11.261a2.59 2.59 0 01-2.59-2.585 2.59 2.59 0 015.178 0c0 1.429-1.16 2.585-2.588 2.585zm0-8.261a5.675 5.675 0 00-5.676 5.676 5.675 5.675 0 005.676 5.675 5.675 5.675 0 005.675-5.675A5.675 5.675 0 0011.99 3z"
      fill="currentColor"
    />
  </svg>
)

const BASE_URL = process.env.PREPR_API_BASE_LEGACY_URL

function endpoint(endpoint) {
  return `${BASE_URL}${endpoint}`
}

export const getGuideEndpoint = id =>
  endpoint(
    `v5/guides/${id}?fields=show{cover{cdn_files{resized{player.width(688).h(340).q(100).format(png)}}}}&exceptions=true`
  )
export const getGuideRangeEndpoint = (id, from, until) =>
  endpoint(
    `v5/guides/${id}?from=${from}&until=${until}&fields=show{cover{cdn_files}}&exceptions=true`
  )

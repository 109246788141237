import React from "react"

export const Instagram = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 20.83H9.453c-.85 0-1.698 0-2.547-.17-2.038-.51-3.227-1.698-3.736-3.736C3 16.245 3 15.566 3 14.887V8.943c0-.849 0-1.698.34-2.377.51-1.868 1.868-2.887 3.735-3.396C7.755 3 8.434 3 9.113 3h5.944c.849 0 1.528 0 2.377.34 1.868.51 3.057 1.868 3.396 3.735.17.85.17 1.699.17 2.378v5.094c0 .85 0 1.698-.17 2.547-.51 2.038-1.698 3.227-3.736 3.736-.849.17-1.528.17-2.377.17-1.019-.17-1.868-.17-2.717-.17zm0-16.472H9.792c-.849 0-1.698 0-2.547.17-1.188.34-2.037 1.02-2.377 2.378-.17.51-.17 1.188-.17 1.698v5.604c0 .679 0 1.528.17 2.207.34 1.359 1.019 2.208 2.377 2.547.51.17 1.189.17 1.698.17h6.453c.51 0 1.02 0 1.359-.17 1.358-.17 2.207-1.019 2.547-2.377.17-.51.17-1.189.17-1.698V8.604c0-.51 0-1.02-.17-1.529-.17-.849-.68-1.698-1.528-2.037-.68-.34-1.19-.51-1.868-.51-1.359 0-2.717-.17-3.906-.17z"
      fill="currentColor"
    />
    <path
      d="M12 7.245a4.566 4.566 0 014.585 4.585A4.566 4.566 0 0112 16.415c-2.547 0-4.585-2.038-4.585-4.755 0-2.377 2.038-4.415 4.585-4.415zm0 1.529a3.044 3.044 0 00-3.056 3.056A3.044 3.044 0 0012 14.887a3.044 3.044 0 003.057-3.057A3.044 3.044 0 0012 8.774zM16.755 8.094c-.51 0-1.02-.51-1.02-1.019 0-.509.51-1.018 1.02-1.018.679 0 1.019.509 1.188 1.018 0 .51-.509 1.02-1.188 1.02z"
      fill="currentColor"
    />
  </svg>
)

export const NEWS_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "b8281a5c-19e8-475c-a37f-9be8ce69a8b1"
    : "78b136bb-164c-47a0-b887-a235136ea7b5"
export const INSTAGRAM_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "00af0b43-0f17-498f-a855-8b5af4a2c397"
    : "a8405c56-4ae6-42ab-8dc4-e727683e64fd"
export const ACTION_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "fba87704-16d4-478a-b986-6897442277aa"
    : "0618af62-f461-410c-8bae-629b93fddddb"
export const PROGRAM_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "e1351cf4-7e6b-4639-93ec-8d27e6afc84e"
    : "13368d24-2acf-47b2-85f4-05e37a0aee93"
export const VACANCY_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "24cfc5fd-754b-4313-984c-7c6cbf8f157e"
    : "571c5f60-65a0-4df2-9393-c6a6cab57d35"
export const FAQ_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "09a4cd0a-519e-42cb-910d-00be9e19ff90"
    : "af0af335-f528-4f59-a17e-36c7966e3fa9"
export const LIST_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "37405e2a-b5b7-42ba-8626-b375a8017b42"
    : "67aa4b34-c0f8-4709-b78d-1cc3672d8862"
export const TRACK_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "984e8c74-814f-4f98-9d89-7c9821b16762"
    : "f6ba716a-805c-40a4-a487-294f9a35b29c"
export const GUIDE_ID =
  process.env.ENVIRONMENT !== "development"
    ? "cde4658e-a211-4999-859f-60fb086005b4"
    : "f8215012-e6d7-42c6-ba32-275e8923fefb"
export const TOP_LIST_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "ffdc2afa-b399-4d1b-861b-5a22e1e2ae58"
    : "15baaab0-fb42-47b4-8313-f3849c35543a"
export const PODCAST_SHOW_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "b11b6ac6-c4d4-4b4e-a7af-1c61062071ca"
    : "02ef74b3-c51e-4c71-87ed-d4f70ec9c3e7"
export const PODCAST_SINGLE_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "5e8f65d3-718f-4da8-9477-a57eb2335fb3"
    : "f56ef976-c5ca-42d1-92bf-471fd4c917ad"
export const NOSTALGIE_PLUS_HOME_ID =
  process.env.ENVIRONMENT !== "development"
    ? "1ecb12e2-c5f6-40c1-8f0c-1222b4c02145"
    : "12b12e61-28e8-4b82-ae39-674cc665b5ce"
export const NOSTALGIE_PLUS_SUB_ID =
  process.env.ENVIRONMENT !== "development"
    ? "0712abdb-fb7b-4ed6-871a-09d67f5058ad"
    : "3c9a2f42-0ab5-4e24-aa16-3a0b4de30bd2"
export const ADVERT_MODEL_ID =
  process.env.ENVIRONMENT !== "development"
    ? "5c3331c4-491a-4aac-a1fd-3d62962e9178"
    : "4ba6578b-f84a-46b3-be26-549fad203bb4"
export const STATION_MODEL_ID =
  process.env.ENVIRONMENT !== 'development'
    ? 'fd5ace31-4bec-413b-8ba6-b79d6f77e002'
    : 'fd5ace31-4bec-413b-8ba6-b79d6f77e002';
export const STATIONS_CAROUSEL_MODEL_ID =
  process.env.ENVIRONMENT !== 'development'
    ? 'a75c9f8c-349a-424f-8d73-d479ee9832a9'
    : 'a75c9f8c-349a-424f-8d73-d479ee9832a9';
export const CONTACT_MODEL_ID =
  process.env.ENVIRONMENT !== 'development'
    ? '520e2a23-0d15-445c-80bc-bd45acb208b2'
    : '520e2a23-0d15-445c-80bc-bd45acb208b2';
export const COMPETITION_RULES_MODEL_ID =
    process.env.ENVIRONMENT !== 'development'
      ? '7ec98517-bc6e-4ba7-b306-c312ccb841e5'
      : '7ec98517-bc6e-4ba7-b306-c312ccb841e5';
export const VACANCIES_MODEL_ID =
    process.env.ENVIRONMENT !== 'development'
      ? '3a6170c4-97f0-4161-92e3-cd4a8d35df5b'
      : '3a6170c4-97f0-4161-92e3-cd4a8d35df5b';
export const FAQS_MODEL_ID =
    process.env.ENVIRONMENT !== 'development'
      ? '25c7d1be-bd99-4544-bf6e-d02debf61844'
      : '25c7d1be-bd99-4544-bf6e-d02debf61844';
export const HOW_TO_LISTEN_MODEL_ID =
    process.env.ENVIRONMENT !== 'development'
      ? '6fccdf1a-7441-4f75-847b-33c3d4c21a3e'
      : '6fccdf1a-7441-4f75-847b-33c3d4c21a3e';
export const LISTEN_PLATFORM_MODEL_ID =
    process.env.ENVIRONMENT !== 'development'
      ? 'ec505ab7-2bde-4785-97c6-6057e147bb9b'
      : 'ec505ab7-2bde-4785-97c6-6057e147bb9b';
export const TOP_LISTS_MODEL_ID =
    process.env.ENVIRONMENT !== "development"
      ? "e655dc56-47bc-4f00-a38b-a4a136edf8ae"
      : "e655dc56-47bc-4f00-a38b-a4a136edf8ae"

export function imageUrl(url, width, height) {
  let newUrl
  if (width && !height) {
    newUrl = url.replace("{format}", `w_${width}`)
  } else if (!width && height) {
    newUrl = url.replace("{format}", `h_${height}`)
  } else if (width && height) {
    newUrl = url.replace("{format}", `w_${width},h_${height}`)
  } else {
    newUrl = url
  }

  return newUrl
}

import React from "react"

export const Clock = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12 2C6.455 2 2 6.486 2 12c0 5.545 4.486 10 10 10 5.545 0 10-4.486 10-10 0-5.545-4.486-10-10-10zm4.455 13.084c-.25.343-.654.467-1.028.312a1.99 1.99 0 01-.436-.25c-1.09-.716-2.181-1.464-3.271-2.15-.468-.31-.686-.684-.654-1.245.03-.779 0-1.558 0-2.337V6.922c0-.28.062-.53.249-.748.28-.28.591-.374.965-.249.405.156.592.467.623.872.032.25 0 .468 0 .717v3.8c0 .188.063.281.218.374.904.592 1.807 1.215 2.71 1.838.219.156.406.28.593.468.28.342.28.747.03 1.09z"
      fill="currentColor
    "
    />
  </svg>
)

import React from "react"

export const Play = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.583 14.096l2.278-2.105-2.517-2.725a2.07 2.07 0 01.114-2.919 2.07 2.07 0 012.919.114l5.733 6.201-5.307 4.91a2.373 2.373 0 01-3.349-.131 2.373 2.373 0 01.132-3.348m5.117 5.532l7.365-6.81-7.635-8.254a4.868 4.868 0 00-6.872-.27 4.868 4.868 0 00-.27 6.872l.62.669-.222.207a5.167 5.167 0 107.018 7.586"
      fill="currentColor"
    />
  </svg>
)

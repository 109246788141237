import React from "react"

export const Twitter = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M23.111 5.157c-.83.36-1.71.598-2.609.707a4.566 4.566 0 002.001-2.51 9.077 9.077 0 01-2.885 1.106 4.533 4.533 0 00-7.74 4.135 12.869 12.869 0 01-9.341-4.743 4.6 4.6 0 00-.608 2.288A4.522 4.522 0 003.94 9.91a4.477 4.477 0 01-2.057-.563v.055a4.533 4.533 0 003.649 4.422c-.395.12-.804.184-1.216.188a5.41 5.41 0 01-.852-.077 4.544 4.544 0 004.235 3.14A9.087 9.087 0 011 18.954a12.792 12.792 0 006.954 2.045A12.813 12.813 0 0020.9 8.086V7.5a9.32 9.32 0 002.211-2.343z"
      fill="currentColor"
    />
  </svg>
)

import { DateTime } from "luxon"

import {
  NEWS_MODEL_ID,
  INSTAGRAM_MODEL_ID,
  ACTION_MODEL_ID,
  PROGRAM_MODEL_ID,
  VACANCY_MODEL_ID,
  LIST_MODEL_ID,
  TOP_LIST_MODEL_ID,
  PODCAST_SHOW_MODEL_ID,
  NOSTALGIE_PLUS_HOME_ID,
  NOSTALGIE_PLUS_SUB_ID,
} from "../constants/models"
export const DATETIME_FORMAT = "dd/LL/yyyy"

export function getSingleImage(image) {
  return image
    ? image.items
      ? image.items.length > 0
        ? image.items[0].cdn_files
          ? image.items[0].cdn_files.items.length > 0
            ? image.items[0].cdn_files.items[0].url
            : ""
          : ""
        : ""
      : ""
    : ""
}

export function getHeaderSingleImage(image) {
  return image
    ? image.items
      ? image.items.length > 0
        ? image.items[0].cdn_files
          ? image.items[0].cdn_files.items.length > 0
            ? image.items[0].cdn_files.items[0].url
            : null
          : null
        : null
      : null
    : null
}

export function getVotingModuleListId(votingmodule_list_id) {
  return votingmodule_list_id ? votingmodule_list_id.body : ""
}

export function getVotingModuleApiPostList(api_postlist) {
  return api_postlist ? api_postlist.body : ""
}

export function getSingleImageName(image) {
  return image
    ? image.items
      ? image.items[0].name
        ? image.items[0].name
        : ""
      : image
    : ""
}

export function getRightTypeOfWebImage(type) {}

export function getVoteSubmittedCopy(voteSubmittedCopy) {
  return voteSubmittedCopy ? voteSubmittedCopy.body : ""
}

export function getProgramCover(cover) {
  return cover ? cover.cdn_files[0].url : ""
}

export function getTitle(title) {
  return title ? title.body : ""
}

export function getFrequency(title) {
  return title ? title.body : ""
}

export function getEmail(email) {
  return email ? email.body : ""
}

export function getName(name) {
  return name ? name.body : ""
}

export function getSpotifyUrl(spotifyUrl) {
  return spotifyUrl ? spotifyUrl.body : null;
}

export function getAppleUrl(appleUrl) {
  return appleUrl ? appleUrl.body : null;
}

export function getTopListId(topListId) {
  return topListId ? topListId.body : ""
}

export function getSubtitle(subTitle) {
  return subTitle ? subTitle.body : ""
}

export function getShortText(shortText) {
  return shortText ? shortText.body : ""
}

export function getPerson(person) {
  return person ? person.body : ""
}

export function getDescription(description) {
  return description ? description.body : ""
}

export function getContact(contact) {
  return contact ? contact.body : ""
}

export function getAsset(cdn_files) {
  return cdn_files ? (cdn_files.items ? cdn_files.items[0].url : null) : null
}

export function getPdf(cdn_files) {
  return cdn_files
    ? cdn_files.items
      ? cdn_files.items[0].cdn_files
        ? cdn_files.items[0].cdn_files.items.length > 0
          ? cdn_files.items[0].cdn_files.items[0].url
          : null
        : null
      : null
    : null
}

export function getExternalUrl(externalUrl) {
  return externalUrl ? externalUrl.body : ""
}

export function getQualifio(qualifio) {
  return qualifio ? qualifio.body : ""
}

export function getQualifioCampaignId(qualifio_campaign_id) {
  return qualifio_campaign_id ? qualifio_campaign_id.body : ""
}

export function getQualifioParticipateLimit(qualifio_participate_limit) {
  return qualifio_participate_limit ? qualifio_participate_limit.value : 1
}

export function getType(type) {
  return type ? type.body : ""
}

export function getTimeSlot(timeslot) {
  return timeslot ? timeslot.body : ""
}

export function getSpotify(spotify) {
  return spotify ? spotify.url : null
}

export function getStationId(stationId) {
  return stationId ? stationId.value : '';
}

export function getMaRadioId(maRadioId) {
  return maRadioId ? maRadioId.body : '';
}

export function getHLSStream(hlsStream) {
  return hlsStream ? hlsStream.body : '';
}

export function getDASHStream(dashStream) {
  return dashStream ? dashStream.body : '';
}

export function getWEBStream(webStream) {
  return webStream ? webStream.body : '';
}

export function getFallbackStream(fallbackStream) {
  return fallbackStream ? fallbackStream.body : '';
}


export function getProvider(provider) {
  return provider ? provider.body : '';
}

export function getStationPosition(position) {
  return position ? position.value : '';
}

export function getShowEpisodes(showEpisodes) {
  return showEpisodes ? showEpisodes.value : false;
}

export function getStationColor(color) {
  if(!color) {
    return '#fe6727'
  };

  return color.body;
}

export function getColor(color) {
  if (color) {
    switch (color.body) {
      case 'dark_orange':
        return '#fe6727';
      case 'light_orange':
        return '#ffa41f';
      case 'light_blue':
        return '#ea27c2';
      case 'green':
        return '#a72ed0';
      case 'pastel_yellow':
        return '#ff71de';
      case 'salmon':
        return '#b01117';
      case 'dark_blue':
        return '#5b1881';
      case 'deep_red':
        return '#f93822';
      case "nostalgie_plus_cyan":
        return "#257c8a"
      case "nostalgie_plus_orange":
        return "#d1af7a"
      case 'nostalgie_orange_play':
        return '#fe6727';
      case 'neneh_cherry_play':
        return '#b01117';
      case 'bowie_blonde_play':
        return '#ffa41f';
      case 'simply_red_play':
        return '#f93822';
      case 'beegee_blush_play':
        return '#ff71de';
      case 'pet_shop_pink_play':
        return '#ea27c2';
      case 'madonna_mauve_play':
        return '#a72ed0';
      case 'prince_purple_play':
        return '#5b1881';
      case 'barry_white_play':
        return '#5b1881';
      case 'grey_play':
        return '#14191e';
      default:
        return "#fe6727"
    }
  }
}

export function getContract(contract) {
  return contract ? contract.body : ""
}

export function getLocation(location) {
  return location ? location.body : ""
}

export function getRecruiter(recruiter) {
  return recruiter ? recruiter.body : ""
}

export function getPublishOn(publishOn) {
  return DateTime.fromISO(publishOn).toFormat(DATETIME_FORMAT)
}

export function getArtist(artist) {
  return artist ? artist.body : ""
}

export function getTrack(track) {
  return track ? track.body : ""
}

export function getLabel(label) {
  return label ? (label.items ? label.items[0].body : label) : ""
}

export function getMediaUrl(media) {
  let array = []
  if (media) {
    media.items.map(item => {
      array.push(item.url)
    })
  }
  return array
}

export function convertToSlugWithOrigin(model_id, slug) {
  const basePath = window.location.protocol + "//" + window.location.host
  if (model_id === NEWS_MODEL_ID) {
    return `${basePath}/nieuws/${slug}`
  } else if (model_id === ACTION_MODEL_ID) {
    return `${basePath}/acties/${slug}`
  } else if (model_id === PROGRAM_MODEL_ID) {
    return `${basePath}/programmas/${slug}`
  } else if (model_id === VACANCY_MODEL_ID) {
    return `${basePath}/vacatures/${slug}`
  } else if (model_id === LIST_MODEL_ID) {
    return `${basePath}/stem/${slug}`
  } else if (model_id === TOP_LIST_MODEL_ID) {
    return `${basePath}/top-lijsten/${slug}`
  } else if (model_id === PODCAST_SHOW_MODEL_ID) {
    return `${basePath}/podcasts/${slug}`
  } else if (model_id === NOSTALGIE_PLUS_HOME_ID) {
    return `${basePath}/nostalgie-plus`
  } else if (model_id === NOSTALGIE_PLUS_SUB_ID) {
    return `${basePath}/nostalgie-plus/${slug}`
  }

  return null
}

export function convertToSlug(model_id, slug) {
  if (model_id === NEWS_MODEL_ID) {
    return `/nieuws/${slug}`
  } else if (model_id === ACTION_MODEL_ID) {
    return `/acties/${slug}`
  } else if (model_id === PROGRAM_MODEL_ID) {
    return `/programmas/${slug}`
  } else if (model_id === VACANCY_MODEL_ID) {
    return `/vacatures/${slug}`
  } else if (model_id === LIST_MODEL_ID) {
    return `/stem/${slug}`
  } else if (model_id === TOP_LIST_MODEL_ID) {
    return `/top-lijsten/${slug}`
  } else if (model_id === PODCAST_SHOW_MODEL_ID) {
    return `/podcasts/${slug}`
  } else if (model_id === NOSTALGIE_PLUS_HOME_ID) {
    return `/nostalgie-plus`
  } else if (model_id === NOSTALGIE_PLUS_SUB_ID) {
    return `/nostalgie-plus/${slug}`
  }

  return null
}

export function getModelType(model_id) {
  if (model_id === NEWS_MODEL_ID) {
    return "news"
  } else if (model_id === INSTAGRAM_MODEL_ID) {
    return "instagram"
  } else if (model_id === ACTION_MODEL_ID) {
    return "action"
  } else if (model_id === LIST_MODEL_ID) {
    return "vote"
  } else if (model_id === TOP_LIST_MODEL_ID) {
    return "top-list"
  } else if (model_id === NOSTALGIE_PLUS_HOME_ID) {
    return "nostalgie-plus-home"
  } else if (model_id === NOSTALGIE_PLUS_SUB_ID) {
    return "nostalgie-plus-sub"
  }
}

export function getInstagramImage(instagramPost) {
  if (instagramPost) {
    return `${instagramPost.url}media/?size=m`
  }

  return null
}

export function getTagsIds(tag) {
  const collection = []
  if (tag) {
    if (tag.items.length > 0) {
      tag.items.map(e => {
        collection.push(e.id)
      })
    }
  }
  return collection
}

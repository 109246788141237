import React from "react"
import PropTypes from "prop-types"
import cx from "classnames";
import {
  Check,
  ChevronLeft,
  ChevronRight,
  Clock,
  Close,
  Facebook,
  Instagram,
  Live,
  Magnifier,
  Mail,
  Play,
  PlayerPlay,
  Spotify,
  TikTok,
  Twitter,
  User,
  Youtube,
} from "./icons"

import "./Icon.scss"
import { isNil } from "lodash";

export const ICONS = Object.freeze({
  magnifier: Magnifier,
  close: Close,
  "chevron-left": ChevronLeft,
  "chevron-right": ChevronRight,
  twitter: Twitter,
  youtube: Youtube,
  tiktok: TikTok,
  facebook: Facebook,
  instagram: Instagram,
  play: Play,
  "player-play": PlayerPlay,
  user: User,
  mail: Mail,
  clock: Clock,
  live: Live,
  spotify: Spotify,
  check: Check
})

const Icon = ({ name, className = "" }) => {
  const IconComponent = ICONS[name]

  return IconComponent ? (
    <i className={cx('icon', !isNil(className) && className)}>
      <IconComponent />
    </i>
  ) : null
}

Icon.propTypes = {
  name: PropTypes.oneOf(Object.keys(ICONS)),
  className: PropTypes.string,
}

export default Icon

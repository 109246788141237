import React from "react"

export const Facebook = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.784 12.568h1.784c.486 0 .486.162.486.648V21h3.405v-8.27h2.595c.162-1.135.324-2.108.487-3.244h-2.595c-.324 0-.486 0-.486-.486V7.216c0-.648.324-1.135 1.135-1.135h1.621c.325 0 .325-.162.325-.324v-2.27c0-.325-.163-.487-.487-.487h-2.432c-1.784 0-3.081 1.135-3.406 2.757-.162.648 0 1.297-.162 2.108v1.297h-2.27c-.325 0-.325.162-.325.487v2.594c0 .162.163.325.325.325z"
      fill="currentColor"
    />
  </svg>
)

import React from "react"

export const TikTok = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.796 7.54a4.404 4.404 0 01-2.757-.972c-.81-.65-1.46-1.622-1.621-2.757 0-.325-.162-.487-.162-.811h-2.92v12.324c0 1.135-.81 2.108-1.783 2.433-.325.162-.649.162-.973.162-.487 0-.81-.162-1.135-.324-.811-.487-1.298-1.298-1.298-2.27a2.561 2.561 0 012.595-2.595c.324 0 .486 0 .81.162V9.973h-.81c-1.622 0-3.081.649-4.216 1.946-.811.973-1.297 2.108-1.297 3.243-.163 1.622.486 3.081 1.621 4.216l.487.487C7.31 20.675 8.445 21 9.742 21h.81c1.136-.162 2.271-.649 3.082-1.622.973-.973 1.621-2.432 1.621-3.892V9c.487.324.973.649 1.622.973.973.324 1.946.649 2.92.649V7.54z"
      fill="currentColor"
    />
  </svg>
)

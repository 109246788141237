import React from "react"

export const ChevronRight = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.786 19.509l7.487-7.523L9.73 4.482c-.62-.62-1.66-.653-2.281-.032-.622.621-.585 1.648.04 2.27l5.315 5.257-5.254 5.295c-.621.625-.658 1.665-.032 2.286.625.62 1.656.572 2.277-.049"
      fill="currentColor"
    />
  </svg>
)

import React from "react"

export const Mail = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M19.837 5.088H4.149c-.983 0-1.79.792-1.79 1.79v10.244c0 .983.792 1.79 1.79 1.79h15.702c.984 0 1.79-.792 1.79-1.79V6.878a1.81 1.81 0 00-1.804-1.79zm-1.145 1.394l-6.707 5.782L5.28 6.482h13.413zm1.145 11.021H4.134a.379.379 0 01-.381-.381V7.025l7.63 6.575c.177.132.397.205.617.205.22 0 .426-.073.617-.205l7.63-6.575v10.097c-.029.205-.19.381-.41.381z"
      fill="currentColor"
    />
  </svg>
)

import React from "react"

export const Magnifier = (props) => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M11.089 15.867a4.8 4.8 0 110-9.598 4.8 4.8 0 010 9.598zm9.453 2.153l-1.796-1.85-.813-.842a8.048 8.048 0 001.211-4.256A8.07 8.07 0 0011.072 3 8.07 8.07 0 003 11.072a8.07 8.07 0 0012.374 6.832l.771.805 1.788 1.837c.626.643 1.717.598 2.435-.103s.8-1.78.174-2.423z"
      fill="currentColor"
    />
  </svg>
)
